import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  margin-right: 0;
  margin-left: auto;
  width: fit-content;
`;

export const NavMenu = styled.div`
  display: flex;
  width: 412.5px;
`;

export const NavLink = styled(Link)`
  color: #fffaf0;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 1.6rem 1rem 2rem;
  text-decoration: none;
  &.active {
    color: #fffaf0;
    font-weight: bold;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
