const Etusivu = () => {
  return (
    <div className="pagesBorder">
      <div className="pagesWrapper">
        <h2>
          Mietityttääkö metsien kohtalo? Kaipaatko paikkaa, jossa voisit olla
          luonnon rauhassa ajatustesi kanssa? Juuri sinä saatat olla
          Metsäystävä!
        </h2>
        <h3>Mikä ihmeen Metsäystävä? </h3>
        <h4>
          Metsäystävyys on ennen kaikkea metsän ja oman itsesi hyvinvoinnin
          edistämistä. Metsäystävä tarjoaa mahdollisuuden päästökompensointiin
          ilman päästöjen aiheuttamista, sekä tilaisuuteen ihan omasta kolkasta
          luonnon helmassa.
        </h4>
        <h3>Miten Metsäystävä toimii?</h3>
        <h4>
          Metsäystävän kaupasta voit valita haluamasi tavan aloittaa
          Metsäystävyyden. Tällä hetkellä tarjoamme taimien istutusta
          puolestasi, mutta pian valikoimaamme liittyy myös kummipuutoiminta,
          joten pidä sivusto mielessäsi!
        </h4>
        <h3>Sijainti</h3>
        <h4>
          Metsäystävä operoi lounaisessa Kanta-Hämeessä, Hattulan, Hämeenlinnan
          ja Tammelan kupeessa.
        </h4>
      </div>
    </div>
  );
};

export default Etusivu;
