import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import './pages/pages.css';

import Navbar from './components/Navbar';
import Etusivu from './pages';
import Kauppa from './pages/kauppa';
import Yhteystiedot from './pages/yhteystiedot';

function App() {
  return (
    <div className="contentWrapper">
      <h1>METSÄYSTÄVÄ</h1>
      {
        <Router>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Etusivu />} />
            <Route path="/kauppa" element={<Kauppa />} />
            <Route path="/yhteystiedot" element={<Yhteystiedot />} />
          </Routes>
        </Router>
      }
    </div>
  );
}

export default App;
