import React from 'react';
import { Nav, NavLink, NavMenu } from './NavbarElements';

const Navbar = () => {
  return (
    <>
      <Nav>
        <NavMenu>
          <NavLink to="/" activestyle="true">
            Etusivu
          </NavLink>
          <NavLink to="/kauppa" activestyle="true">
            Kauppa
          </NavLink>
          <NavLink to="/yhteystiedot" activestyle="true">
            Yhteystiedot
          </NavLink>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;
