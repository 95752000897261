const Yhteystiedot = () => {
  return (
    <div className="pagesBorder">
      <div className="pagesWrapper">
        <h2>Yhteystiedot</h2>
        <h3>
          Mikäli tilauksesi tai Metsäystävyys herättää kysmyksiä vastauksia
          niihin voit saada alla olevasta osoitteesta.
        </h3>
        <h2>myynti@metsaystava.fi</h2>
      </div>
    </div>
  );
};

export default Yhteystiedot;
