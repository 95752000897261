import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Kauppa = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE,
        form.current,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => console.log(result.text),
        (error) => console.log(error.text)
      );

    e.target.reset();
  };

  return (
    <div className="pagesBorder">
      <div className="pagesWrapper">
        <h2>Kauppa</h2>
        <h3>
          Tervetuloa Metsäystävän kauppaan! Alta voit valita haluamasi tuotteen
          ja lähettää tilauksen. Tilauksen vastaanotettuamme lähetämme laskun
          sekä tarkemmat tiedot tilauksesta sähköpostiisi.
        </h3>
        <form ref={form} onSubmit={sendEmail}>
          <label>Etu- ja sukunimi: </label>
          <input type="text" name="user_name" />
          <label> Sähköpostiosoite: </label>
          <input type="email" name="user_email" />
          <select id="selection" name="selection">
            <option value="ei tuotetta">Valite haluamasi tuote tästä</option>
            <option value="Kolmen taimen istutus - 10€">
              Kolmen taimen istutus - 10€
            </option>
          </select>
          <input
            type="submit"
            value="Lähetä"
            onClick={() =>
              alert(
                'Kiitos tilauksestasi, tilausvahvistus on lähetetty sähköpostiisi!'
              )
            }
          />
        </form>
      </div>
    </div>
  );
};

export default Kauppa;
